import 'isomorphic-fetch';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { CartProvider } from 'react-use-cart';
// import { GraphQLClient, ClientContext } from 'graphql-hooks';
import { ToastContainer } from 'react-toastify';

// import StripeProvider from './src/components/StripeProvider';
import SSRLayout from './src/components/SSRLayout';
// import FullScreenLayout from './src/components/FullScreenLayout';
import {
  handleItemAdded,
  handleItemUpdated,
  handleItemRemoved,
} from './src/utils/cart-helpers';

const toastOptions = {
  position: 'top-right',
  draggable: false,
  toastClassName:
    'bg-primary text-white text-center px-2 py-3 shadow-none rounded-lg',
  progressClassName: 'h-0',
  closeButton: false,
  autoClose: 2000,
};

// const client = new GraphQLClient({
//   url: '/.netlify/functions/graphql',
// });

const randomCartId = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const wrapPageElement = ({ element, props }) => {
  return <SSRLayout {...props}>{element}</SSRLayout>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <CartProvider
        id={randomCartId()}
        onItemAdd={handleItemAdded}
        onItemUpdate={handleItemUpdated}
        onItemRemove={handleItemRemoved}
      >
        {element}
      </CartProvider>
      <ToastContainer {...toastOptions} />
    </>
  );
};

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window !== 'undefined' && !(`IntersectionObserver` in window)) {
    const intersectionObserver = import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`, intersectionObserver);
  }
};
