import React from 'react';

// import '../styles/main.css';
// import '../styles/main.scss';

// import Banner from './Banner';
// import Header from './Header';
// import Footer from './Footer';
import SEO from './SEO';
// import RotateDevice from './RotateDevice';

function SSRLayout({ children }) {
  return (
    <React.Fragment>
      <SEO />
      {children}
    </React.Fragment>
  );
}

export default SSRLayout;
